import React from 'react';
import * as S from './App.style.js';
import SvgImg from './SvgImg/SvgImg';

import { Controlled as Editor } from 'react-codemirror2';
import 'codemirror/lib/codemirror.css';
import 'codemirror/theme/material.css';
import 'codemirror/mode/xml/xml';

import prettier from 'prettier/standalone';
import htmlParser from 'prettier/parser-html';

function makePrettier(svg) {
  try {
    return prettier.format(svg, { parser: 'html', plugins: [ htmlParser ] })
  } catch (error) {
    console.error('Could not make it prettier:', error)
    return svg
  }
}

const initSvg = `
<svg width="800" height="800" viewBox="0 0 100 100">
  <circle cx="50" cy="50" r="50" fill="hotpink" />
</svg>
`.replace('\n', '')

export default class App extends React.Component {
  state = {
    svg: initSvg,
    savedSvg: void 0,
    showImg: false,
  }
  onKeyDown = (_editor, event) => {
    const setSvg = svg => this.setState({ svg });
    const setSavedSvg = savedSvg => this.setState({ savedSvg });
    const setShowImg = showImg => this.setState({ showImg });
    const { svg, savedSvg, showImg } = this.state;

    if (event.ctrlKey) {
      // eslint-disable-next-line default-case
      switch (event.key) {
        case 's':
        case 'S':
          // Save
          setSavedSvg(svg);
          event.preventDefault();
          return;

        case 'l':
        case 'L':
          // Load saved
          setSvg(savedSvg)
          event.preventDefault();
          return;

        case 'i':
        case 'I':
          setShowImg(!showImg);
          return;

        case 'p':
        case 'P':
          setSvg(makePrettier(svg))
          event.preventDefault();
          return;
      }
    }
  }
  render() {
    const setSvg = svg => this.setState({ svg });
    const setSavedSvg = savedSvg => this.setState({ savedSvg });
    const setShowImg = showImg => this.setState({ showImg });
    const { svg, savedSvg, showImg } = this.state;

    return (
      <S.App>
        <Editor
          options={{
            mode: 'xml',
            theme: 'material',
            lineNumbers: true
          }}
          value={svg}
          onBeforeChange={(_editor, _data, value) => setSvg(value)}
          onKeyDown={this.onKeyDown}
        />
        <S.Toolbar>
          <S.Button onClick={() => setSavedSvg(svg)} title="ctrl+S" disabled={svg === savedSvg}>
            Save
          </S.Button>
          <S.Button onClick={() => setSvg(savedSvg)} title="ctrl+L" disabled={svg === savedSvg}>
            Load
          </S.Button>
          <S.Button onClick={() => setSvg(makePrettier(svg))} title="ctrl+P">
            Prettier
          </S.Button>
          <S.Button onClick={() => setShowImg(!showImg)} title="ctrl+I">
            Show as {showImg ? 'inlne SVG' : 'image'}
          </S.Button>
        </S.Toolbar>
        {showImg ? (
          <S.Flex>
            <SvgImg svg={svg} />
            <SvgImg svg={savedSvg} />
          </S.Flex>
        ) : (
          <S.Flex>
            <S.SvgContainer dangerouslySetInnerHTML={{ __html: svg }} />
            <S.SvgContainer dangerouslySetInnerHTML={{ __html: savedSvg }} />
          </S.Flex>
        )}
      </S.App>
    );
  }
}
