import React from 'react';
import * as S from './SvgImg.style.js';

// Declaration of xml namespace, required when using SVG as image
// rather than just inlined in html
const ns = 'xmlns="http://www.w3.org/2000/svg"'

export default function SvgImg({ svg = '' }) {
  const svgWithNs = svg.includes(ns)
    ? svg
    : svg.replace('<svg ', `<svg ${ns} `);

  console.log(`svgWithNs`, svgWithNs); // DEBUG

  return <S.SvgImg src={`data:image/svg+xml;charset=utf-8,${svgWithNs}`} />
}
