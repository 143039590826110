import styled from 'styled-components';

export const App = styled.div`
  .CodeMirror {
    max-width: 100%;
    width: 100%;
    height: 30vh;
  }
`;

export const Toolbar = styled.div`
  @media (max-width: 767px) {
    display: flex;
    justify-content: space-between;
  }
  @media (min-width: 768px) {
    button ~ button {
      margin-left: 10px;
    }
  }

  background-color: #333;

`;

export const Button = styled.button`
  border: none;
  background-color: #333;
  color: white;
  font-size: 16px;
  padding: 4px 8px 2px;
  cursor: pointer;

  &:disabled {
    color: #888;
  }
`;

export const Flex = styled.div`
  display: flex;
  @media (max-width: 767px) {
    flex-direction: column;
  }
`;

export const SvgContainer = styled.div`
  width: 100%;
  height: 69vh;
  @media (max-width: 767px) {
    height: 100vw;
  }
  svg {
    max-width: 100%;
    max-height: 100%;
  }

  border: 1px solid;
  border-color: ${p => p.saved ? '#222' : '#666'};
`;
